;(function (global) {
	global.dateDropperSetup.languages['ko'] = {
		name: '\uc870\uc120\ub9d0',
		m: {
			s: [
				'1\uc6d4',
				'2\uc6d4',
				'3\uc6d4',
				'4\uc6d4',
				'5\uc6d4',
				'6\uc6d4',
				'7\uc6d4',
				'8\uc6d4',
				'9\uc6d4',
				'10\uc6d4',
				'11\uc6d4',
				'12\uc6d4',
			],
			f: [
				'1\uc6d4',
				'2\uc6d4',
				'3\uc6d4',
				'4\uc6d4',
				'5\uc6d4',
				'6\uc6d4',
				'7\uc6d4',
				'8\uc6d4',
				'9\uc6d4',
				'10\uc6d4',
				'11\uc6d4',
				'12\uc6d4',
			],
		},
		w: {
			s: [
				'\uc77c',
				'\uc6d4',
				'\ud654',
				'\uc218',
				'\ubaa9',
				'\uae08',
				'\ud1a0',
			],
			f: [
				'\uc77c\uc694\uc77c',
				'\uc6d4\uc694\uc77c',
				'\ud654\uc694\uc77c',
				'\uc218\uc694\uc77c',
				'\ubaa9\uc694\uc77c',
				'\uae08\uc694\uc77c',
				'\ud1a0\uc694\uc77c',
			],
		},
	}
})((globalThis.CodeCrumbs = globalThis.CodeCrumbs || {}))
